<template>
    <div class="container-allarticle">
        <van-list>
            <van-cell v-for="item in articleList" :key="item.articleId">
                <div class="article-list">
                    <div class="article-box" v-if="!item.isDeleted">
                        <img
                                :src="item.headImgUrl ? `${imgUrl}/${item.headImgUrl}` : morenImg"
                                alt=""
                        />
                        <div class="article-info">
                            <div
                                    class="article-info-top"
                                    @click="loadArticle(item.articleId)"
                            >
                                <span class="topping" v-if="item.isTop">置顶</span>
                                <span class="material" v-if="item.isMaterial && artType !== 2"
                                >素材库</span
                                >
                                <span class="test-info">{{ item.title }}</span>
                            </div>
                            <p class="article-type" v-if="item.typeNames">{{ item.typeNames }}</p>
                        </div>
                        <icon-svg
                                iconClass="iconshare-2"
                                class="share"
                                v-if="artType === 2"
                                @click="handleShareWeChat(item)"
                        />
                        <!-- <div class="share" v-if="artType===2" @click="handleShareWeChat(item)"></div> -->
                        <!-- <div class="recommend-val" v-if="artType===2&&myArticle !== 3">推荐值<span>566679</span></div> -->
                        <div
                                class="recommend-val"
                                v-if="artType === 2 && myArticle === 3"
                                @click="handleDelArticle(item.articleId)"
                        >
                            <icon-svg iconClass="icontrash-2" class="del-article-icon"/>
                        </div>
                        <div v-if="artType === 1" class="source-info">
                            <span>{{ item.createMerchantName }}</span>
                            <span>{{ item.createName }}</span>
                        </div>
                    </div>
                    <div class="my_articles" v-if="!item.isDeleted && myArticle === 3">
                        <div>
                            <icon-svg iconClass="iconfile-text" class=""/>
                            <span>线索 {{ item.countOfClues }}</span>
                        </div>
                        <div>
                            <icon-svg iconClass="iconeye" class=""/>
                            <span>查看 {{ item.countOfViews }}</span>
                        </div>
                        <div>
                            <icon-svg iconClass="iconshare" class=""/>
                            <span>转发 {{ item.countOfForward }}</span>
                        </div>
                    </div>
                </div>
            </van-cell>
        </van-list>
        <!-- 删除 -->
        <div class="del-wrapper">
            <van-overlay :show="showDel" @click="show = false">
                <div class="wrapper" @click.stop>
                    <div class="block">
                        <p>删除</p>
                        <p>确定删除该文章？</p>
                        <van-grid clickable :column-num="2">
                            <van-grid-item
                            ><span @click="handelConfirm('')">取消</span></van-grid-item
                            >
                            <van-grid-item
                            ><span @click="handelConfirm(articleId)">确定</span>
                            </van-grid-item>
                        </van-grid>
                    </div>
                </div>
            </van-overlay>
        </div>
        <!-- 弹出分享框 -->
        <div class="share_box">
          <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">
            <div class="poster-details" v-if="showImg" ref="imageWrapper">
              <img :src="posterPic" crossOrigin="anonymous"/>
              <div class="text-qrcode">
                <div class="user-info-tag">
                  <div class="user-info">
                    <img :src="headImgUrl">
                    <div class="left-text">
                      <p>{{ userForm.name }}</p>
                      <p>{{ userForm.merchantName }}</p>
                    </div>
                  </div>
                  <div class="user-tag">
                    <span class="user-tag-item" v-for="(it,index) in userTags" :key="index">{{ it }}</span>
                  </div>
                </div>
                <div class="qrCodeUrl-all">
                  <div class="qrCodeUrl-cls">
                    <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
                  </div>
                  <p>立即扫码</p>
                  <p>了解更多内容</p>
                </div>
              </div>
            </div>
            <div v-else class="posterImg-hb">
              <img class="card-img" :src="imgSrc" ref="cardImgRef" />
            </div>
          </div>

          <van-share-sheet
                    v-model:show="showShare"
                    title="分享"
                    :options="shareOptions"
                    cancel-text="取消"
                    @select="onSelect"
                    safe-area-inset-bottom
            >
            </van-share-sheet>
        </div>
    </div>
</template>

<script>
    import {nextTick, onMounted, reactive, ref, toRefs,watch} from "vue";
    import {reprint,createPosterAppShare,getQrCodeUrl} from "@/api/common";
    import {useRouter} from "vue-router";
    import {deleteArticle,getArticleDetailsUrl} from "../../../api/huokeArticle";
    import {flutterShowToast} from "@/utils/appChannel";
    import { copyStr,loadImages } from "@/utils/commonUtil";
    import html2canvas from "html2canvas";
    import {posterShare} from "@/utils/posterShare"
    import {getUserCardByPoster} from "@/api/user/userCard"

    // import {addNum} from "../../../api/huokeArticle"
    import  {findPosterImg} from "@/api/file"
    import {Toast} from "vant";
    export default {
        props: {
            articleList: Object,
            artType: Number,
            myArticle: Number,
            twoType: Number,
            typesStr: String,
            oneType: Number,
            isSearch: String,
            searchVal: String,
            fromPoster:String,
            addPoster:String,
        },
        setup(props, {emit}) {
          const router = useRouter();
          const imageWrapper = ref(null);
          const cardImgRef = ref(null);
          const state = reactive({
                userForm:{},
                userTags:[],
                headImgUrl:'',
                showSharePoster:false,
                shareJumpUrl:'',
                posterId:'',
                imgSrc:'',
                qrCodeUrl:'',
                posterName:'',
                posterPic:'',
                sharePosterImg:'',
                showOverlay: false,
                showImg: true,
                shareOptions:[],
                defaultImg: require("../../../assets/images/article-defaultImg-big.png"),
              //=======================
                imgUrl: process.env.VUE_APP_WATCH_FILE_API,
                morenImg: require("../../../assets/images/article-defaultimg-small.png"),
                showDel: false,
                number: 1,
                showShare: false,
                articleId: "",
                articleItem: {},
                articles: props.articleList,
                options: [
                    {name: "微信", icon: require("../../../assets/icons/weChat2.png")},
                    {name: "朋友圈", icon: require("../../../assets/icons/friends2.png")},
                    {name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
                    {name: "分享海报", icon: require("@/assets/icons/share_poster.png") },
                ],
              posterOptions: [
                    {name: "微信分享", icon: require("@/assets/icons/weChat2.png")},
                    {name: "朋友圈分享", icon: require("@/assets/icons/friends2.png")},
                ],
            });
          watch(() => state.showShare, (newVal, oldVal) => {
            console.log('新值：',newVal,'老值',oldVal)
            if (state.showShare===false){
              state.showOverlay = false
              state.showImg = true
            }
          })
            const handleShareWeChat = (item) => {
              getUserCardByPoster().then(res=>{
                if (res){
                  if (res.headImgUrl){
                    state.headImgUrl = state.imgUrl+'/'+res.headImgUrl
                  }else {
                    state.headImgUrl = require("@/assets/poster_headerImg_default.png")
                  }
                  state.userForm = res
                  state.userTags = res.impressionLabel.split(',')
                  if (state.userTags.length>4){
                    state.userTags = state.userTags.slice(0,4)
                  }
                }
              })
            let params = {
              sourceId:item.articleId,//分享的id
              sourceType:1,//来源类别 1-文章 2-案例 3-视频 4-工地
            }
              getQrCodeUrl(params).then((res)=>{
                state.shareJumpUrl = res.jumpUrl
                if(res.qrCodeUrl){
                  state.qrCodeUrl = res.qrCodeUrl
                }
                state.showSharePoster = true
              })
              // const p = {
              //   busiId:item.articleId,
              //   busiCat:'poster',
              //   groupId:'sharePosterPic',
              // }
              //获取海报大图
              findPosterImg({
                busiId:item.articleId,
                busiCat:'poster',
                groupId:'sharePosterPic',
              }).then((res)=>{
                console.log("海报图片==============》",res)
                if (res.length>0){
                  findPosterImg({
                    busiId:item.articleId,
                    busiCat:'poster',
                    groupId:'sharePosterPic:S',
                  }).then((sImg)=>{
                    state.sharePosterImg = sImg[0].url?state.imgUrl+"/"+sImg[sImg.length-1].url:"https://wca.ujiaku.com/upload/app_defaultImg/article-default.png"
                  })
                  state.posterPic = res[0].url?state.imgUrl+"/"+ res[0].url:state.defaultImg
                }else {
                  state.posterPic = state.defaultImg
                  state.sharePosterImg = "https://wca.ujiaku.com/upload/app_defaultImg/article-default.png"
                }
                state.showShare = true;
              })
                console.log("文章列表",item)
                state.articleId = item.articleId;
                state.articleItem = item;
                state.shareOptions = state.options
                // state.sharePosterImg = item.headImgUrl?state.imgUrl+"/"+item.smallCoverPic:"https://wca.ujiaku.com/upload/app_defaultImg/article-default.png"
                state.posterName = item.title
            };
            const loadArticle = async (id) => {
              console.log("props.addPoster===============》",props.addPoster)
               if(props.addPoster === 'true'){
                router.push({
                  path: "/articleVisitors/visitorDetails",
                  name: "visitorDetails",
                  params:{
                    addPoster:true,
                  },
                  query: {
                    id: id,
                    artType: props.artType,
                    twoType: props.twoType,
                    oneType: props.oneType,
                    typesStr: props.typesStr,
                    isSearch: props.isSearch,
                    searchVal: props.searchVal,
                  },
                });
                return;
              }

              if(props.fromPoster === 'true'){
                router.push({
                  path: "/articleVisitors/visitorDetails",
                  name: "visitorDetails",
                  params:{
                    fromPoster:true,
                  },
                  query: {
                    id: id,
                    artType: props.artType,
                    twoType: props.twoType,
                    oneType: props.oneType,
                    typesStr: props.typesStr,
                    isSearch: props.isSearch,
                    searchVal: props.searchVal,
                  },
                });
                return;
              }
                if (props.artType === 1) {
                    router.push({
                        path: "/articleManagement/articleDetails",
                        query: {
                            id: id,
                            artType: props.artType,
                            twoType: props.twoType,
                            oneType: props.oneType,
                            typesStr: props.typesStr,
                            isSearch: props.isSearch,
                            searchVal: props.searchVal,
                        },
                    });
                } else if (props.artType === 2) {
                    router.push({
                        path: "/articleVisitors/visitorDetails",
                        query: {
                            id: id,
                            myType: props.myArticle,
                            twoType: props.twoType,
                            typesStr: props.typesStr,
                            isSearch: props.isSearch,
                            artType: props.artType,
                            searchVal: props.searchVal,
                        },
                    });
                }
            };
            const loadPoster = ()=>{
              savePoster()
              setTimeout(()=>{
                Toast.clear()
              })
            }
            //微信
            const onSelect = (index) => {
                let shareType;
                let content;
                let merchantId = localStorage.getItem('merchantId')
              if(index.name === "微信分享"||index.name === "朋友圈分享"){
                if(state.showSharePoster===true){
                  Toast.loading({
                    message: '正在\n打开微信...',
                    forbidClick: true,
                    duration:3000,
                    className:'posterToast',
                  });
                  let p = {
                    sourceId: state.articleId,//分享的id
                    sourceType:1,//来源类别 1-文章 2-案例 3-视频 4-工地
                    jumpUrl:state.shareJumpUrl
                  }
                  createPosterAppShare(p).then((res)=>{
                    console.log("创建二海报",res)
                  })
                  let param = {
                    imgSrc:state.imgSrc,
                    id:state.articleId,
                    bigPosterImgUrl:state.sharePosterImg,
                  }
                  setTimeout(()=>{
                    posterShare(index,param)
                  })
                }
              }else if (index.name === "复制链接"){
                getArticleDetailsUrl(state.articleItem.articleId).then((res)=>{
                  copyStr(res)
                  state.showShare = false
                })
              }else if(index.name === "分享海报"){
                // if(state.showSharePoster===true){
                  Toast.loading({
                    message: '生成海报中...',
                    forbidClick: true,
                    duration:0,
                    className:'posterToast',
                  });
                  state.shareOptions = []
                  state.shareOptions = state.posterOptions
                  // state.showImg = true
                  state.showOverlay = true;
                  let arr = []
                    arr.push(state.posterPic)
                    arr.push(state.qrCodeUrl)
                    arr.push(state.headImgUrl)
                   loadImages(arr,loadPoster)
                //   setTimeout(()=>{
                //   savePoster()
                // },300)
                // }
              }else {
                if (index.name === "微信") {
                  shareType = "shareToMiniProgram";
                  content = `${process.env.VUE_APP_WECHAT_SHARE}/weChatShare/${state.articleId}/1/${merchantId}`
                } else if (index.name === "朋友圈") {
                  //  shareType = "shareH5PageToTimeLine";
                  shareType = "shareH5PageToTimeLine";
                  content=`${process.env.VUE_APP_WECHAT_SHARE}/weChatShare/${state.articleId}/2/${merchantId}`
                }
                let param = {
                  miniProgramType:state.articleItem.jumpApplets,
                  shareType: shareType,
                  urlEntity: {
                    title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
                    content: content,
                  },
                  title: state.articleItem.title,
                  shareImg: state.articleItem.smallCoverPic
                      ? `${process.env.VUE_APP_WATCH_FILE_API}/${state.articleItem.smallCoverPic}`
                      : 'https://wca.ujiaku.com/upload/app_defaultImg/article-default.png',
                };
                reprint({busiId: state.articleId});
                flutterShowToast(param);
              }
            };

            onMounted(() => {
            });
            //删除
            const handleDelArticle = (id) => {
                state.showDel = !state.showDel;
                state.articleId = id;
            };
            //确认删除
            const handelConfirm = async (artcleId) => {
                state.showDel = !state.showDel;
                if (artcleId) {
                    let result = await deleteArticle(artcleId);
                    if (result) {
                        emit("delete-ok", result);
                    }
                }
            };
          //画图
          const savePoster = () =>{
            console.log("保存")
            state.showOverlay = true;
            // html2canvasChange()
            nextTick(() => {
              // var canvas2 = document.createElement("canvas");
              let _canvas = imageWrapper.value;
              const box = window.getComputedStyle(_canvas);
              // DOM 节点计算后宽高
              const width = parseInt(box.width, 10);
              const height = parseInt(box.height, 10);
              // 获取像素比
              const scaleBy = DPR();
              // 创建自定义 canvas 元素
              const canvas = document.createElement("canvas");
              // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
              canvas.width = width * scaleBy;
              canvas.height = height * scaleBy;
              // 设定 canvas css宽高为 DOM 节点宽高
              canvas.style.width = `${width}px`;
              canvas.style.height = `${height}px`;
              html2canvas(_canvas, {
                // scale:3,
                useCORS: true,
                background: "#ffffff",
                height: document.getElementById('saveposter').scrollHeight,
                windowHeight: document.getElementById('saveposter').scrollHeight,
                canvas,
              }).then((canvas) => {
                var context = canvas.getContext("2d");
                context.mozImageSmoothingEnabled = false;
                context.webkitImageSmoothingEnabled = false;
                context.msImageSmoothingEnabled = false;
                context.imageSmoothingEnabled = false;
                let dataURL = canvas.toDataURL("image/png");
                state.showImg = false
                nextTick(() => {
                  cardImgRef.value.style.width = width + "px";
                  cardImgRef.value.style.height = height + "px";
                  state.imgSrc = dataURL;
                  state.showImg = false
                });
              });
            });
          }
          const DPR = () => {
            if (window.devicePixelRatio && window.devicePixelRatio > 1) {
              return window.devicePixelRatio;
            }
            return 1;
          };
            return {
                loadPoster,
                imageWrapper,
                cardImgRef,
                savePoster,
                DPR,
                handelConfirm,
                handleDelArticle,
                onSelect,
                handleShareWeChat,
                loadArticle,
                ...toRefs(state),
            };
        },
    };
</script>

<style lang="scss">
    .posterToast{
      //color: #289fff;
      z-index: 1000000001 !important;
    }
    .container-allarticle {
        flex: 1;
        //遮罩层
        height: 100%;
      .van-toast{
        color: #289fff;
        z-index: 1000000001 !important;
      }

        .del-wrapper {
            .van-overlay {
                z-index: 102;
            }

            .wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                .block {
                    font-size: 14px;
                    width: 303px;
                    height: 133px;
                    border-radius: 10px;
                    color: #333333;
                    background-color: #fff;

                    > div {
                        > div:nth-child(1) {
                            .van-hairline {
                                border-radius: 0 0 0 10px;
                                padding: 0;
                                color: #999999;
                            }
                        }

                        > div:nth-child(2) {
                            .van-hairline {
                                border-radius: 0 0 10px 0;
                                padding: 0;
                            }

                            [class*="van-hairline"]::after {
                                border-right: none;
                            }
                        }
                    }

                    [class*="van-hairline"]::after {
                        border-bottom: none;
                    }

                    > p {
                        font-weight: 600;
                        font-size: 14px;
                        margin: 17px 16px;
                    }

                    span {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 46px;
                    }
                }
            }
        }

        .van-cell {
            padding: 0;
        }

        .article-list {

            .my_articles {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 34px;
                border-bottom: 1px solid #f1f3f6;

                > div {
                    height: 34px;
                    display: flex;
                    color: #999999;
                    align-items: center;
                }
            }
        }

        .article-box {
            display: flex;
            height: 85px;
            position: relative;
            align-items: center;
            border-bottom: 0.5px solid #f1f3f6;
            padding: 0 16px;
            > img {
                display: block;
                height: 68px;
                width: 68px;
                border-radius: 8px;
                margin-right: 6px;
                object-fit: cover;
            }

            .article-info {
                height: 68px;
                width: 247px;
                text-align: left;
                font-size: 12px;
                position: relative;

                .article-info-top {
                    height: 48px;
                    width: auto;
                    color: white;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .topping {
                        height: 20px;
                        width: 40px;
                        text-align: center;
                        float: left;
                        position: relative;
                        left: 0;
                        background: linear-gradient(0deg, #ff6e01, #ff6e01);
                        border-radius: 4px 0px;
                        margin-right: 4px;
                    }

                    .material {
                        height: 20px;
                        width: 60px;
                        float: left;
                        margin-right: 3px;
                        text-align: center;
                        background: #ffcf23;
                        border-radius: 4px 0px;
                    }
                    .test-info {
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        word-break: break-all;
                    }
                }
                .article-type {
                    display: block;
                    height: 14px;
                    text-align: center;
                    line-height: 14px;
                    padding: 5px 2px;
                    transform: scale(0.85);
                    position: absolute;
                    bottom: -14px;
                    color: #999999;
                    border: 1px solid #999999;
                    padding: 3px 5px;
                    border-radius: 5px;
                }
            }
            .share {
                position: relative;
                top: -18px;
                right: 0;
                width: 24px;
                height: 24px;
            }

            .recommend-val {
                color: #999999;
                transform: scale(0.85);
                position: absolute;
                right: 5px;
                bottom: 0;

                .del-article-icon {
                    height: 25px;
                    width: 25px;
                    margin-right: 10px;
                    color: #ff5c4b;
                }

                img {
                    height: 20px;
                    width: 20px;
                    margin-right: 14px;
                }
                span {
                    color: #ff6e01;
                    font-size: 22px;
                    font-weight: 600;
                    margin: 0 5px;
                }
            }

            .source-info {
                color: #999999;
                transform: scale(0.85);
                //  font-size: 12px;
                position: absolute;
                right: 0;
                bottom: 0;

                span {
                    margin: 0 5px;
                }
            }
        }

        .share_box {
            .van-share-sheet__cancel::before {
                height: 0;
            }

            .van-share-sheet__cancel {
                height: 48px;
                width: 343px;
                background-color: #f7f7f7;
                color: #333333;
                border-radius: 5px;
                margin-left: 15px;
                margin-bottom: 15px;
                margin-top: 15px;
            }

            .van-share-sheet__options {
                color: #333333;
                display: flex;
                padding: 0 20px;
                justify-content: space-around;
            }
        }
      .wrapperPoster{
        //margin-top: 100px;
        height: 400px;
        overflow-x: hidden;
        width: auto;
        //position: absolute;
        position: fixed;
        top: 60px;
        //bottom: 0;
        left: 0;
        right: 0;
        .poster-details{
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 250px;
          overflow-x: hidden;
          >img{
            width: 250px;
            display: block;
          }
          //.text-qrcode{
          //  background-color: #ffff;
          //  display: flex;
          //  justify-content: space-between;
          //  padding: 10px;
          //  .left-text{
          //    text-align: left;
          //    p {
          //      margin: 0;
          //      padding: 0;
          //    }
          //    p:nth-child(1){
          //      font-size: 14px;
          //      font-weight: 400;
          //      line-height: 18px;
          //      color: #333333;
          //    }
          //    p:nth-child(2){
          //      font-size: 9px;
          //      color: #999999;
          //      line-height: 20px;
          //    }
          //  }
          //  .qrCodeUrl-all{
          //    position: relative;
          //    text-align: center;
          //    height: 60px;
          //    .qrCodeUrl-cls{
          //      width: 60px;
          //      height: 60px;
          //      background-color: #FFFFFF;
          //      border-radius: 30px;
          //      position: relative;
          //      top: -33px;
          //      text-align: center;
          //      img{
          //        width: 50px;
          //        border-radius: 25px;
          //        position: relative;
          //        margin-top: 50%;
          //        transform: translateY(-50%);
          //      }
          //    }
          //    >p{
          //      position: relative;
          //      font-size: 9px;
          //      font-weight: 400;
          //      color: #999999;
          //      top: -40px;
          //    }
          //  }
          //}
          .text-qrcode{
            background-color: #ffff;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            flex: 1;
            .user-info-tag{
              .user-info{
                display: flex;
                align-items: center;
                img{
                  border-radius: 20px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                  flex-shrink: 0;
                }
                .left-text{
                  text-align: left;
                  //flex: 1;
                  margin-left: 4px;
                  p {
                    margin: 0;
                    padding: 0;
                  }
                  p:nth-child(1){
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #333333;
                  }
                  p:nth-child(2){
                    font-size: 10px;
                    color: #333333;
                    //line-height: 10px;
                  }
                }
              }
              .user-tag{
                display: flex;
                flex-wrap: wrap;
                .user-tag-item{
                  margin-top: 5px;
                  margin-right: 5px;
                  //padding-left: 5px;
                  padding:2px 5px;
                  color: #289FFF;
                  background-color: #E6ECFF;
                  font-size: 9px;
                  border-radius: 4px;
                }
              }
            }


            .qrCodeUrl-all{
              //position: relative;
              //text-align: center;
              //height: 60px;
              border-left: 1px solid #F1F3F6;
              padding-left: 18px;
              margin-left: 8px;
              flex-shrink:0;
              .qrCodeUrl-cls{
                //width: 60px;
                //height: 60px;
                background-color: #FFFFFF;
                border-radius: 30px;
                //position: relative;
                //top: -33px;
                //text-align: center;
                img{
                  width: 60px;
                  //border-radius: 25px;
                  //position: relative;
                  //margin-top: 50%;
                  //transform: translateY(-50%);
                }
              }
              >p{
                //position: relative;
                text-align: center;
                font-size: 10px;
                font-weight: 400;
                color: #666666;
                margin: 0;
                //top: -40px;
              }
            }
          }
        }
        .posterImg-hb{
          height: 400px;
          overflow-x: hidden;
        }
      }
    }
</style>
